// deps
import { defineMessage } from "react-intl";

// Catégories

/** Gîte */
export const HOTEL_CATEGORY_VALUE_GITE = "gite";
/** Chambre d'hôtes */
export const HOTEL_CATEGORY_VALUE_CHAMBRE_HOTE = "hote";
/** City Break */
export const HOTEL_CATEGORY_VALUE_CITY_BREAK = "city_break";
/** Camping */
export const HOTEL_CATEGORY_VALUE_CAMPING = "camping";
/** Chalet */
export const HOTEL_CATEGORY_VALUE_CHALET = "chalet";
/** Chalet Loisirs */
export const HOTEL_CATEGORY_VALUE_CHALET_LOISIRS = "chalet-loisirs";
/** Château */
export const HOTEL_CATEGORY_VALUE_CHATEAU = "chateau";
/** Ferme */
export const HOTEL_CATEGORY_VALUE_FERME = "ferme";
/** Maison */
export const HOTEL_CATEGORY_VALUE_MAISON = "maison";
/** Insolite */
export const HOTEL_CATEGORY_VALUE_INSOLITE = "insolite";
/** Gîte de groupe */
export const HOTEL_CATEGORY_VALUE_GROUPE = "groupe";
/** Villa */
export const HOTEL_CATEGORY_VALUE_VILLA = "villa";
/** Appartement */
export const HOTEL_CATEGORY_VALUE_APPARTEMENT = "appartement";
/** Éco-gîte */
export const HOTEL_CATEGORY_VALUE_ECOGITE = "ecogite";
/** Haut de gamme */
export const HOTEL_CATEGORY_VALUE_HAUT_GAMME = "haut-gamme";
/** Gîte pour enfants */
export const HOTEL_CATEGORY_VALUE_GITE_ENFANT = "gite-enfant";
/** Fuste */
export const HOTEL_CATEGORY_VALUE_FUSTE = "fuste";
/** Bungalow */
export const HOTEL_CATEGORY_VALUE_BUNGALOW = "bungalow";
/** Moulin */
export const HOTEL_CATEGORY_VALUE_MOULIN = "moulin";
/** Maison d'hôte à la ferme */
export const HOTEL_CATEGORY_VALUE_HOTE_FERME = "hote-ferme";
/** Camping à la ferme */
export const HOTEL_CATEGORY_VALUE_CAMPING_FERME = "camping-ferme";
/** Gourmandise à la ferme */
export const HOTEL_CATEGORY_VALUE_GOURMANDE_FERME = "gourmande-ferme";
/** Gourmandise locale */
export const HOTEL_CATEGORY_VALUE_GOURMANDE_LOCAUX = "gourmande-locaux";
/** Auberge familiale */
export const HOTEL_CATEGORY_VALUE_AUBERGE_FAMILIALE = "auberge-familiale";
/** Hôtel */
export const HOTEL_CATEGORY_VALUE_HOTEL = "hotel";
/** Village club */
export const HOTEL_CATEGORY_VALUE_VILLAGE_CLUB = "village-club";
/** Ferme pédagogique */
export const HOTEL_CATEGORY_VALUE_FERME_PEDAGOGIQUE = "ferme-pedagogique";
/** Mobil-Home */
export const HOTEL_CATEGORY_VALUE_MOBIL_HOME = "mobil-home";
/** Camping Mobil-Home */
export const HOTEL_CATEGORY_VALUE_CAMPING_MOBIL_HOME = "camping-mobil-home";
/** Chambre chez l'habitant */
export const HOTEL_CATEGORY_VALUE_CHAMBRE_HABITANT = "chambre-habitant";

export const HOTEL_CATEGORIES = {
  [HOTEL_CATEGORY_VALUE_GITE]: {
    id: HOTEL_CATEGORY_VALUE_GITE,
    label: "Gîte",
    label2: "un gîte",
    color: "#5CBB82",
  },
  [HOTEL_CATEGORY_VALUE_CHAMBRE_HOTE]: {
    id: HOTEL_CATEGORY_VALUE_CHAMBRE_HOTE,
    label: "Chambre d'hôtes",
    label2: "une chambre d'hôtes",
    color: "#DB9645",
  },
  [HOTEL_CATEGORY_VALUE_CITY_BREAK]: {
    id: HOTEL_CATEGORY_VALUE_CITY_BREAK,
    label: "City Break",
    label2: "un city Break",
    color: "#40BFB0",
  },
  [HOTEL_CATEGORY_VALUE_CAMPING]: {
    id: HOTEL_CATEGORY_VALUE_CAMPING,
    label: "Camping",
    label2: "un camping",
    color: "#98B24D",
  },
  [HOTEL_CATEGORY_VALUE_CHALET]: {
    id: HOTEL_CATEGORY_VALUE_CHALET,
    label: "Chalet",
    label2: "un chalet",
    color: "#BF6E40",
  },
  [HOTEL_CATEGORY_VALUE_CHALET_LOISIRS]: {
    id: HOTEL_CATEGORY_VALUE_CHALET_LOISIRS,
    label: "Chalet loisirs",
    label2: "un chalet loisirs",
    color: "#808080",
  },
  [HOTEL_CATEGORY_VALUE_CHATEAU]: {
    id: HOTEL_CATEGORY_VALUE_CHATEAU,
    label: "Château",
    label2: "un château",
    color: "#1A9CC3",
  },
  [HOTEL_CATEGORY_VALUE_FERME]: {
    id: HOTEL_CATEGORY_VALUE_FERME,
    label: "Ferme",
    label2: "une ferme",
    color: "#156543",
  },
  [HOTEL_CATEGORY_VALUE_MAISON]: {
    id: HOTEL_CATEGORY_VALUE_MAISON,
    label: "Maison",
    label2: "une maison",
    color: "black",
  },
  [HOTEL_CATEGORY_VALUE_INSOLITE]: {
    id: HOTEL_CATEGORY_VALUE_INSOLITE,
    label: "Insolite",
    label2: "un lieu insolite",
    color: "#CF7C7B",
  },
  [HOTEL_CATEGORY_VALUE_GROUPE]: {
    id: HOTEL_CATEGORY_VALUE_GROUPE,
    label: "Gîte de groupe",
    label2: "un gîte de groupe",
    color: "#4D4DB2",
  },
  [HOTEL_CATEGORY_VALUE_VILLA]: {
    id: HOTEL_CATEGORY_VALUE_VILLA,
    label: "Villa",
    label2: "une villa",
    color: "#000000",
  },
  [HOTEL_CATEGORY_VALUE_APPARTEMENT]: {
    id: HOTEL_CATEGORY_VALUE_APPARTEMENT,
    label: "Appartement",
    label2: "un appartement",
    color: "#5C8ABB",
  },
  [HOTEL_CATEGORY_VALUE_ECOGITE]: {
    id: HOTEL_CATEGORY_VALUE_ECOGITE,
    label: "Ecogîte",
    label2: "un ecogîte",
    color: "#5CBB82",
  },
  [HOTEL_CATEGORY_VALUE_HAUT_GAMME]: {
    id: HOTEL_CATEGORY_VALUE_HAUT_GAMME,
    label: "Haut de gamme",
    label2: "un lieu haut de gamme",
    color: "#818181",
  },
  [HOTEL_CATEGORY_VALUE_GITE_ENFANT]: {
    id: HOTEL_CATEGORY_VALUE_GITE_ENFANT,
    label: "Gîtes d'enfants",
    label2: "un gîtes d’enfants",
    color: "#D84646",
  },
  [HOTEL_CATEGORY_VALUE_FUSTE]: {
    id: HOTEL_CATEGORY_VALUE_FUSTE,
    label: "Fustes",
    label2: "un fustes",
    color: "#B97BCF",
  },
  [HOTEL_CATEGORY_VALUE_BUNGALOW]: {
    id: HOTEL_CATEGORY_VALUE_BUNGALOW,
    label: "Chalets/Bungalows",
    label2: "un chalets/bungalows",
    color: "#BF6E40",
  },
  [HOTEL_CATEGORY_VALUE_MOULIN]: {
    id: HOTEL_CATEGORY_VALUE_MOULIN,
    label: "Moulins à vent",
    label2: "un moulin à vent",
    color: "#442277",
  },
  [HOTEL_CATEGORY_VALUE_HOTE_FERME]: {
    id: HOTEL_CATEGORY_VALUE_HOTE_FERME,
    label: "Chambres d’hôtes à la ferme",
    label2: "une chambre d’hôtes à la ferme",
    color: "#27C24C",
  },
  [HOTEL_CATEGORY_VALUE_CAMPING_FERME]: {
    id: HOTEL_CATEGORY_VALUE_CAMPING_FERME,
    label: "Campings à la ferme",
    label2: "un camping à la ferme",
    color: "#98B24D",
  },
  [HOTEL_CATEGORY_VALUE_GOURMANDE_FERME]: {
    id: HOTEL_CATEGORY_VALUE_GOURMANDE_FERME,
    label: "Étapes gourmandes à la ferme",
    label2: "une étape gourmandes à la ferme",
    color: "#B24D4D",
  },
  [HOTEL_CATEGORY_VALUE_GOURMANDE_LOCAUX]: {
    id: HOTEL_CATEGORY_VALUE_GOURMANDE_LOCAUX,
    label: "une étape gourmandes produits locaux",
    color: "#7A97B8",
  },
  [HOTEL_CATEGORY_VALUE_AUBERGE_FAMILIALE]: {
    id: HOTEL_CATEGORY_VALUE_AUBERGE_FAMILIALE,
    label: "une auberge familiale",
    color: "#E07132",
  },
  [HOTEL_CATEGORY_VALUE_HOTEL]: {
    id: HOTEL_CATEGORY_VALUE_HOTEL,
    label: "un hôtel",
    color: "#40BFB0",
  },
  [HOTEL_CATEGORY_VALUE_VILLAGE_CLUB]: {
    id: HOTEL_CATEGORY_VALUE_VILLAGE_CLUB,
    label: "un village-club",
    color: "#E0BA32",
  },
  [HOTEL_CATEGORY_VALUE_FERME_PEDAGOGIQUE]: {
    id: HOTEL_CATEGORY_VALUE_FERME_PEDAGOGIQUE,
    label: "une ferme pédagogiques",
    color: "#D36BDC",
  },
  [HOTEL_CATEGORY_VALUE_MOBIL_HOME]: {
    id: HOTEL_CATEGORY_VALUE_MOBIL_HOME,
    label: "un mobil-home",
    color: "#575757",
  },
  [HOTEL_CATEGORY_VALUE_CAMPING_MOBIL_HOME]: {
    id: HOTEL_CATEGORY_VALUE_CAMPING_MOBIL_HOME,
    label: "un camping et mobil-home",
    color: "#98B24D",
  },
  [HOTEL_CATEGORY_VALUE_CHAMBRE_HABITANT]: {
    id: HOTEL_CATEGORY_VALUE_CHAMBRE_HABITANT,
    label: "un chambre chez l’habitant",
    color: "#DB9645",
  },
};

export const HOTEL_CATEGORY_INTL_MESSAGES = {
  byValue: {
    [HOTEL_CATEGORY_VALUE_GITE]: {
      label: defineMessage({
        defaultMessage: "gîte",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "un gîte",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "le gîte",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_CHAMBRE_HOTE]: {
      label: defineMessage({
        defaultMessage: "chambre d’hôtes",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "une chambre d’hôtes",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "la chambre d’hôtes",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_CITY_BREAK]: {
      label: defineMessage({
        defaultMessage: "city-break",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "un city-break",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "le city-break",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_CAMPING]: {
      label: defineMessage({
        defaultMessage: "camping",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "un camping",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "le camping",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_CHALET]: {
      label: defineMessage({
        defaultMessage: "chalet",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "un chalet",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "le chalet",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_CHALET_LOISIRS]: {
      label: defineMessage({
        defaultMessage: "chalet loisirs",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "un chalet loisirs",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "le chalet loisirs",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_CHATEAU]: {
      label: defineMessage({
        defaultMessage: "château",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "un château",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "le château",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_FERME]: {
      label: defineMessage({
        defaultMessage: "ferme",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "une ferme",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "la ferme",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_MAISON]: {
      label: defineMessage({
        defaultMessage: "maison",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "une maison",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "la maison",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_INSOLITE]: {
      label: defineMessage({
        defaultMessage: "insolite",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "un lieu insolite",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "le lieu insolite",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_GROUPE]: {
      label: defineMessage({
        defaultMessage: "gîte de groupe",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "un gîte de groupe",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "le gîte de groupe",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_VILLA]: {
      label: defineMessage({
        defaultMessage: "villa",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "une villa",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "la villa",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_APPARTEMENT]: {
      label: defineMessage({
        defaultMessage: "appartement",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "un appartement",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "l’appartement",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_ECOGITE]: {
      label: defineMessage({
        defaultMessage: "ecogîte",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "un ecogîte",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "l’ecogîte",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_HAUT_GAMME]: {
      label: defineMessage({
        defaultMessage: "haut de gamme",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "un lieu haut de gamme",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "le lieu haut de gamme",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_GITE_ENFANT]: {
      label: defineMessage({
        defaultMessage: "gîtes d’enfants",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "un gîte d’enfants",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "le gîte d’enfants",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_FUSTE]: {
      label: defineMessage({
        defaultMessage: "fustes",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "un fuste",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "le fuste",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_BUNGALOW]: {
      label: defineMessage({
        defaultMessage: "chalets/bungalows",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "un chalet/bungalow",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "le chalet/bungalow",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_MOULIN]: {
      label: defineMessage({
        defaultMessage: "moulins à vent",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "un moulin à vent",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "le moulin à vent",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_HOTE_FERME]: {
      label: defineMessage({
        defaultMessage: "chambres d’hôtes à la ferme",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "une chambre d’hôtes à la ferme",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "la chambre d’hôtes à la ferme",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_CAMPING_FERME]: {
      label: defineMessage({
        defaultMessage: "campings à la ferme",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "un camping à la ferme",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "le camping à la ferme",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_GOURMANDE_FERME]: {
      label: defineMessage({
        defaultMessage: "étapes gourmandes à la ferme",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "une étape gourmande à la ferme",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "l’étape gourmande à la ferme",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_GOURMANDE_LOCAUX]: {
      label: defineMessage({
        defaultMessage: "étapes gourmandes produits locaux",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "une étape gourmande produits locaux",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "l’étape gourmande produits locaux",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_AUBERGE_FAMILIALE]: {
      label: defineMessage({
        defaultMessage: "auberge familiale",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "une auberge familiale",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "l’auberge familiale",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_HOTEL]: {
      label: defineMessage({
        defaultMessage: "hôtel",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "un hôtel",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "l’hôtel",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_VILLAGE_CLUB]: {
      label: defineMessage({
        defaultMessage: "village-club",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "un village-club",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "le village-club",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_FERME_PEDAGOGIQUE]: {
      label: defineMessage({
        defaultMessage: "ferme pédagogiques",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "une ferme pédagogique",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "la ferme pédagogique",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_MOBIL_HOME]: {
      label: defineMessage({
        defaultMessage: "mobil-home",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "un mobil-home",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "le mobil-home",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_CAMPING_MOBIL_HOME]: {
      label: defineMessage({
        defaultMessage: "camping et mobil-home",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "un camping et mobil-home",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "le camping et mobil-home",
        description: "catégorie d’hébergement",
      }),
    },
    [HOTEL_CATEGORY_VALUE_CHAMBRE_HABITANT]: {
      label: defineMessage({
        defaultMessage: "chambre chez l’habitant",
        description: "catégorie d’hébergement",
      }),
      labelWithIndefiniteArticle: defineMessage({
        defaultMessage: "un chambre chez l’habitant",
        description: "catégorie d’hébergement",
      }),
      labelWithPersonalPronoun: defineMessage({
        defaultMessage: "la chambre chez l’habitant",
        description: "catégorie d’hébergement",
      }),
    },
  },
};

export const HOTEL_CATEGORY_LIST = Object.values(HOTEL_CATEGORIES);

// Type de gestion

/** Planning exclusif */
export const HOTEL_GESTION_VALUE_SR = "SR";
/** Planning partagé */
export const HOTEL_GESTION_VALUE_PP = "DG";
/** Location directe */
export const HOTEL_GESTION_VALUE_LD = "LD";

export const HOTEL_GESTIONS = {
  [HOTEL_GESTION_VALUE_SR]: {
    id: HOTEL_GESTION_VALUE_SR,
    label: "Planning exclusif",
    accountLabel: "commercialisation exclusive",
    description:
      "Concentrez-vous sur l’accueil de vos vacanciers et laissez-nous le reste : promotion et valorisation de votre gîte auprès de la bonne clientèle, gestion des réservations et de l’administratif",
    selectable: true,
    accountHotelProjectSelectable: true,
  },
  [HOTEL_GESTION_VALUE_PP]: {
    id: HOTEL_GESTION_VALUE_PP,
    label: "Planning partagé",
    accountLabel: "commercialisation partagée",
    description:
      "Vous pouvez commercialiser votre structure et accepter vos propres locations, tout en bénéficiant de la visibilité de la marque et de l’apport d’affaires de notre Centrale de Réservation",
    selectable: true,
    accountHotelProjectSelectable: true,
  },
  [HOTEL_GESTION_VALUE_LD]: {
    id: HOTEL_GESTION_VALUE_LD,
    label: "Location directe",
    selectable: true,
    accountHotelProjectSelectable: false,
  },
};

export const HOTEL_GESTION_LIST = Object.values(HOTEL_GESTIONS);

/**
 * Room trophies
 */

export const HOTEL_TROPHY_VALUE_GDF_2017 = "GDF_2017";
export const HOTEL_TROPHY_VALUE_GDF_2018 = "GDF_2018";
export const HOTEL_TROPHY_VALUE_GDF_2019 = "GDF_2019";
export const HOTEL_TROPHY_VALUE_GDF_2020 = "GDF_2020";
export const HOTEL_TROPHY_VALUE_GDF_2021 = "GDF_2021";
export const HOTEL_TROPHY_VALUE_GDF_2022 = "GDF_2022";
export const HOTEL_TROPHY_VALUE_GDF_2023 = "GDF_2023";
export const HOTEL_TROPHY_VALUE_GDF_2024 = "GDF_2024";

export const HOTEL_TROPHIES = {
  [HOTEL_TROPHY_VALUE_GDF_2017]: {
    id: HOTEL_TROPHY_VALUE_GDF_2017,
    label: "2017",
  },
  [HOTEL_TROPHY_VALUE_GDF_2018]: {
    id: HOTEL_TROPHY_VALUE_GDF_2018,
    label: "2018",
  },
  [HOTEL_TROPHY_VALUE_GDF_2019]: {
    id: HOTEL_TROPHY_VALUE_GDF_2019,
    label: "2019",
  },
  [HOTEL_TROPHY_VALUE_GDF_2020]: {
    id: HOTEL_TROPHY_VALUE_GDF_2020,
    label: "2020",
  },
  [HOTEL_TROPHY_VALUE_GDF_2021]: {
    id: HOTEL_TROPHY_VALUE_GDF_2021,
    label: "2021",
  },
  [HOTEL_TROPHY_VALUE_GDF_2022]: {
    id: HOTEL_TROPHY_VALUE_GDF_2022,
    label: "2022",
  },
  [HOTEL_TROPHY_VALUE_GDF_2023]: {
    id: HOTEL_TROPHY_VALUE_GDF_2023,
    label: "2023",
  },
  [HOTEL_TROPHY_VALUE_GDF_2024]: {
    id: HOTEL_TROPHY_VALUE_GDF_2024,
    label: "2024",
  },
};

export const HOTEL_TROPHY_INTL_MESSAGES = {
  byValue: {
    [HOTEL_TROPHY_VALUE_GDF_2017]: {
      label: defineMessage({
        defaultMessage: "trophée Gîtes de France® 2017",
        description: "trophée Gîtes de France",
      }),
    },
    [HOTEL_TROPHY_VALUE_GDF_2018]: {
      label: defineMessage({
        defaultMessage: "trophée Gîtes de France® 2018",
        description: "trophée Gîtes de France",
      }),
    },
    [HOTEL_TROPHY_VALUE_GDF_2019]: {
      label: defineMessage({
        defaultMessage: "trophée Gîtes de France® 2019",
        description: "trophée Gîtes de France",
      }),
    },
    [HOTEL_TROPHY_VALUE_GDF_2020]: {
      label: defineMessage({
        defaultMessage: "trophée Gîtes de France® 2020",
        description: "trophée Gîtes de France",
      }),
    },
    [HOTEL_TROPHY_VALUE_GDF_2021]: {
      label: defineMessage({
        defaultMessage: "trophée Gîtes de France® 2021",
        description: "trophée Gîtes de France",
      }),
    },
    [HOTEL_TROPHY_VALUE_GDF_2022]: {
      label: defineMessage({
        defaultMessage: "trophée Gîtes de France® 2022",
        description: "trophée Gîtes de France",
      }),
    },
    [HOTEL_TROPHY_VALUE_GDF_2023]: {
      label: defineMessage({
        defaultMessage: "trophée Gîtes de France® 2023",
        description: "trophée Gîtes de France",
      }),
    },
    [HOTEL_TROPHY_VALUE_GDF_2024]: {
      label: defineMessage({
        defaultMessage: "trophée Gîtes de France® 2024",
        description: "trophée Gîtes de France",
      }),
    },
  },
};

export const HOTEL_TROPHY_LIST = Object.values(HOTEL_TROPHIES);

// Nature de l'hébergement

/** Structure classique */
export const HOTEL_NATURE_VALUE_COMMON = "co";
/** Structure complexe */
export const HOTEL_NATURE_VALUE_PARENT = "pa";
/**  * Sous-structure */
export const HOTEL_NATURE_VALUE_CHILD = "ch";

export const HOTEL_NATURES = {
  [HOTEL_NATURE_VALUE_COMMON]: {
    id: HOTEL_NATURE_VALUE_COMMON,
    label: "structure classique",
  },
  [HOTEL_NATURE_VALUE_PARENT]: {
    id: HOTEL_NATURE_VALUE_PARENT,
    label: "structure complexe",
  },
  [HOTEL_NATURE_VALUE_CHILD]: {
    id: HOTEL_NATURE_VALUE_CHILD,
    label: "sous-structure",
  },
};

export const HOTEL_NATURE_LIST = Object.values(HOTEL_NATURES);

// Type de contrat

/** Hôtel de type gîte */
export const HOTEL_TYPE_VALUE_GITE = "G";
/**Hôtel de type maison d'hôtes */
export const HOTEL_TYPE_VALUE_CHAMBRE_HOTE = "H";
/** Hôtel de type gîte de groupe */
export const HOTEL_TYPE_VALUE_GITE_GROUPE = "P";
/** Hôtel de type gîte d’étape */
export const HOTEL_TYPE_VALUE_GITE_ETAPE = "E";
/** Hôtel de type camping */
export const HOTEL_TYPE_VALUE_CAMPING = "C";

export const HOTEL_TYPES = {
  [HOTEL_TYPE_VALUE_GITE]: {
    id: HOTEL_TYPE_VALUE_GITE,
    label: "Location saisonnière",
    selectable: true,
    accountIcon: "gite",
    metrics: {
      visible: true,
      color: "#5A5A5A",
    },
    recommendedNature: HOTEL_NATURE_VALUE_COMMON,
  },
  [HOTEL_TYPE_VALUE_CHAMBRE_HOTE]: {
    id: HOTEL_TYPE_VALUE_CHAMBRE_HOTE,
    label: "Chambres d’hôtes",
    selectable: true,
    accountIcon: "chambre-hote",
    metrics: {
      visible: true,
      color: "#DB9645",
    },
    recommendedNature: HOTEL_NATURE_VALUE_PARENT,
  },
  [HOTEL_TYPE_VALUE_GITE_GROUPE]: {
    id: HOTEL_TYPE_VALUE_GITE_GROUPE,
    label: "Gîte de groupe",
    selectable: true,
    accountIcon: "gite-groupe",
    metrics: {
      visible: true,
      color: "#4D4DB2",
    },
    recommendedNature: HOTEL_NATURE_VALUE_COMMON,
  },
  [HOTEL_TYPE_VALUE_CAMPING]: {
    id: HOTEL_TYPE_VALUE_CAMPING,
    label: "Camping",
    selectable: true,
    accountIcon: "camping",
    metrics: {
      visible: true,
      color: "#98B24D",
    },
    recommendedNature: HOTEL_NATURE_VALUE_PARENT,
  },
  [HOTEL_TYPE_VALUE_GITE_ETAPE]: {
    id: HOTEL_TYPE_VALUE_GITE_ETAPE,
    label: "Gîte d’étape",
    selectable: false,
    metrics: {
      visible: false,
      color: "#804DB2",
    },
    recommendedNature: HOTEL_NATURE_VALUE_COMMON,
  },
};

export const HOTEL_TYPE_INTL_MESSAGES = {
  byValue: {
    [HOTEL_TYPE_VALUE_GITE]: {
      label: defineMessage({
        defaultMessage: "location saisonnière",
        description: "type d’hébergement",
      }),
      label2: defineMessage({
        defaultMessage: "gîte",
        description: "type d’hébergement",
      }),
    },
    [HOTEL_TYPE_VALUE_CHAMBRE_HOTE]: {
      label: defineMessage({
        defaultMessage: "chambres d’hôtes",
        description: "type d’hébergement",
      }),
      label2: defineMessage({
        defaultMessage: "chambre",
        description: "type d’hébergement",
      }),
    },
    [HOTEL_TYPE_VALUE_GITE_GROUPE]: {
      label: defineMessage({
        defaultMessage: "gîte de groupe",
        description: "type d’hébergement",
      }),
    },
    [HOTEL_TYPE_VALUE_CAMPING]: {
      label: defineMessage({
        defaultMessage: "camping",
        description: "type d’hébergement",
      }),
    },
    [HOTEL_TYPE_VALUE_GITE_ETAPE]: {
      label: defineMessage({
        defaultMessage: "gîte d’étape",
        description: "type d’hébergement",
      }),
    },
  },
};

export const HOTEL_TYPE_LIST = Object.values(HOTEL_TYPES);

// États

/** Hôtel enregistré, non activé */
export const HOTEL_STATE_VALUE_REGISTERED = "re";
/** Hôtel activé */
export const HOTEL_STATE_VALUE_ENABLED = "en";
/** Hôtel désactivé */
export const HOTEL_STATE_VALUE_DISABLED = "di";
/** Hôtel supprimé */
export const HOTEL_STATE_VALUE_TRASHED = "tr";

export const HOTEL_STATES = {
  [HOTEL_STATE_VALUE_REGISTERED]: {
    id: HOTEL_STATE_VALUE_REGISTERED,
    label: "en attente",
    metrics: {
      visible: true,
      color: "#EE903A",
    },
  },
  [HOTEL_STATE_VALUE_ENABLED]: {
    id: HOTEL_STATE_VALUE_ENABLED,
    label: "activé",
    metrics: {
      visible: true,
      color: "#23AE44",
    },
  },
  [HOTEL_STATE_VALUE_DISABLED]: {
    id: HOTEL_STATE_VALUE_DISABLED,
    label: "désactivé",
    metrics: {
      visible: true,
      color: "#442277",
    },
  },
  [HOTEL_STATE_VALUE_TRASHED]: {
    id: HOTEL_STATE_VALUE_TRASHED,
    label: "supprimé",
    metrics: {
      visible: false,
      color: "#D84646",
    },
  },
};

export const HOTEL_STATE_LIST = Object.values(HOTEL_STATES);

// Orientation

/** Nord */
export const HOTEL_ORIENTATION_VALUE_NORTH = "n";
/** Est */
export const HOTEL_ORIENTATION_VALUE_EAST = "e";
/** Sud */
export const HOTEL_ORIENTATION_VALUE_SOUTH = "s";
/** Ouest */
export const HOTEL_ORIENTATION_VALUE_WEST = "o";
/** Nord/Est */
export const HOTEL_ORIENTATION_VALUE_NORTH_EAST = "ne";
/** Nord/Ouest */
export const HOTEL_ORIENTATION_VALUE_NORTH_WEST = "no";
/** Sud/Est */
export const HOTEL_ORIENTATION_VALUE_SOUTH_EAST = "se";
/** Sud/Ouest */
export const HOTEL_ORIENTATION_VALUE_SOUTH_WEST = "so";

export const HOTEL_ORIENTATIONS = {
  [HOTEL_ORIENTATION_VALUE_NORTH]: {
    id: HOTEL_ORIENTATION_VALUE_NORTH,
    label: "Nord",
  },
  [HOTEL_ORIENTATION_VALUE_EAST]: {
    id: HOTEL_ORIENTATION_VALUE_EAST,
    label: "Est",
  },
  [HOTEL_ORIENTATION_VALUE_SOUTH]: {
    id: HOTEL_ORIENTATION_VALUE_SOUTH,
    label: "Sud",
  },
  [HOTEL_ORIENTATION_VALUE_WEST]: {
    id: HOTEL_ORIENTATION_VALUE_WEST,
    label: "Ouest",
  },
  [HOTEL_ORIENTATION_VALUE_NORTH_EAST]: {
    id: HOTEL_ORIENTATION_VALUE_NORTH_EAST,
    label: "Nord/Est",
  },
  [HOTEL_ORIENTATION_VALUE_NORTH_WEST]: {
    id: HOTEL_ORIENTATION_VALUE_NORTH_WEST,
    label: "Nord/Ouest",
  },
  [HOTEL_ORIENTATION_VALUE_SOUTH_EAST]: {
    id: HOTEL_ORIENTATION_VALUE_SOUTH_EAST,
    label: "Sud/Est",
  },
  [HOTEL_ORIENTATION_VALUE_SOUTH_WEST]: {
    id: HOTEL_ORIENTATION_VALUE_SOUTH_WEST,
    label: "Sud/Ouest",
  },
};

export const HOTEL_ORIENTATION_LIST = Object.values(HOTEL_ORIENTATIONS);

// Attribut des lits avec la capacité par lit

export const HOTEL_BED_ATTRIBUTES_DEFINITION = {
  beds_80x190: {
    label: "80×190",
    label2: "Lits 80×190",
    capacity: 1,
  },
  beds_90x190: {
    label: "90×190",
    label2: "Lits 90×190",
    capacity: 1,
  },
  beds_120x190: {
    label: "120×190",
    label2: "Lits 120×190",
    capacity: 1,
  },
  beds_140x190: {
    label: "140×190",
    label2: "Lits 140×190",
    capacity: 2,
  },
  beds_160x200: {
    label: "160×200",
    label2: "Lits 160×200",
    capacity: 2,
  },
  beds_180x200: {
    label: "180×200",
    label2: "Lits 180×200",
    capacity: 2,
  },
  beds_200x200: {
    label: "200×200",
    label2: "Lits 200×200",
    capacity: 2,
  },
  beds_bunk_included: {
    label: "superposé(s) inclu(s)",
    label2: "dont superposé(s)",
    capacity: 2,
  },
};

// Mode de chauffage

/** Chauffage électrique */
export const HOTEL_HEATING_VALUE_ELEC = "elec";
/** Chauffage au bois */
export const HOTEL_HEATING_VALUE_BOIS = "bois";
/** Chaudière à pellets/Granulés */
export const HOTEL_HEATING_VALUE_CPEL = "cpel";
/** Poêle à pellets/granulés */
export const HOTEL_HEATING_VALUE_PPEL = "ppel";
/** Pompe à Chaleur */
export const HOTEL_HEATING_VALUE_POMP = "pomp";
/** Chauffage au gaz */
export const HOTEL_HEATING_VALUE_GAZ = "gaz";
/** Chauffage fioul */
export const HOTEL_HEATING_VALUE_FIOUL = "fioul";
/** Chauffage solaire */
export const HOTEL_HEATING_VALUE_SOLAI = "solai";
/** Chauffage au sol */
export const HOTEL_HEATING_VALUE_SOL = "sol";
/** Chauffage par géothermie */
export const HOTEL_HEATING_VALUE_GEO = "geo";
/** Chauffage par aérothermie */
export const HOTEL_HEATING_VALUE_AERO = "aero";
/** Céréales */
export const HOTEL_HEATING_VALUE_CERE = "cere";
/** Chauffage par le plafond */
export const HOTEL_HEATING_VALUE_RAD = "rad";

export const HOTEL_HEATINGS = {
  [HOTEL_HEATING_VALUE_ELEC]: {
    id: HOTEL_HEATING_VALUE_ELEC,
    label: "chauffage électrique",
  },
  [HOTEL_HEATING_VALUE_BOIS]: {
    id: HOTEL_HEATING_VALUE_BOIS,
    label: "chauffage au bois",
  },
  [HOTEL_HEATING_VALUE_CPEL]: {
    id: HOTEL_HEATING_VALUE_CPEL,
    label: "chaudière à pellets/granulés",
  },
  [HOTEL_HEATING_VALUE_PPEL]: {
    id: HOTEL_HEATING_VALUE_PPEL,
    label: "poêle à pellets/granulés",
  },
  [HOTEL_HEATING_VALUE_POMP]: {
    id: HOTEL_HEATING_VALUE_POMP,
    label: "pompe à chaleur",
  },
  [HOTEL_HEATING_VALUE_GAZ]: {
    id: HOTEL_HEATING_VALUE_GAZ,
    label: "chauffage au gaz",
  },
  [HOTEL_HEATING_VALUE_FIOUL]: {
    id: HOTEL_HEATING_VALUE_FIOUL,
    label: "chauffage au fioul",
  },
  [HOTEL_HEATING_VALUE_SOLAI]: {
    id: HOTEL_HEATING_VALUE_SOLAI,
    label: "chauffage solaire",
  },
  [HOTEL_HEATING_VALUE_SOL]: {
    id: HOTEL_HEATING_VALUE_SOL,
    label: "chauffage au sol",
  },
  [HOTEL_HEATING_VALUE_GEO]: {
    id: HOTEL_HEATING_VALUE_GEO,
    label: "chauffage par géothermie",
  },
  [HOTEL_HEATING_VALUE_AERO]: {
    id: HOTEL_HEATING_VALUE_AERO,
    label: "chauffage par aérothermie",
  },
  [HOTEL_HEATING_VALUE_CERE]: {
    id: HOTEL_HEATING_VALUE_CERE,
    label: "céréales",
  },
  [HOTEL_HEATING_VALUE_RAD]: {
    id: HOTEL_HEATING_VALUE_RAD,
    label: "chauffage par le plafond",
  },
};

export const HOTEL_HEATING_LIST = Object.values(HOTEL_HEATINGS);

// Traduction de l’étage de l’hôtel

export const HOTEL_FLOOR_INTL_MESSAGES = {
  positive: defineMessage({
    defaultMessage:
      "{floor, select, 0 {rez-de-chaussée} 1 {1er étage} 98 {rez-de-jardin} 99 {entresol} other {{floor}ème étage}}",
  }),
  negative: defineMessage({
    defaultMessage: "sous-sol n°{floor}",
  }),
};

// Attributs associés aux lits

export const HOTEL_BED_ATTRIBUTE_LIST = [
  "beds80",
  "beds90",
  "beds120",
  "beds140",
  "beds160",
  "beds180",
  "beds200",
  "bedsOverlaid",
];

export const HOTEL_BED_TYPE_INTL_MESSAGES = {
  with_count: defineMessage({
    defaultMessage:
      "{bedCount, plural, one {# lit} other {# lits}} {bedType, select, beds80 {80×190} beds90 {90×190} beds120 {120×190} beds140 {140×190} beds160 {160×200} beds180 {180×200} beds200 {200×200} bedsOverlaid {{bedCount, plural, one {superposé} other {superposés}}} other {}}",
  }),
};

// Handicaps

/** Mental */
export const HOTEL_HANDICAP_VALUE_MENTAL = "men";
/** Moteur */
export const HOTEL_HANDICAP_VALUE_MOTEUR = "mot";
/** Visuel */
export const HOTEL_HANDICAP_VALUE_VISUEL = "vis";
/** Auditif */
export const HOTEL_HANDICAP_VALUE_AUDITIF = "aud";

export const HOTEL_HANDICAPS = {
  [HOTEL_HANDICAP_VALUE_MENTAL]: {
    id: HOTEL_HANDICAP_VALUE_MENTAL,
    label: "mental",
  },
  [HOTEL_HANDICAP_VALUE_MOTEUR]: {
    id: HOTEL_HANDICAP_VALUE_MOTEUR,
    label: "moteur",
  },
  [HOTEL_HANDICAP_VALUE_VISUEL]: {
    id: HOTEL_HANDICAP_VALUE_VISUEL,
    label: "visuel",
  },
  [HOTEL_HANDICAP_VALUE_AUDITIF]: {
    id: HOTEL_HANDICAP_VALUE_AUDITIF,
    label: "auditif",
  },
};

export const HOTEL_HANDICAP_INTL_MESSAGES = {
  byValue: {
    [HOTEL_HANDICAP_VALUE_MENTAL]: {
      label: defineMessage({
        defaultMessage: "mental",
        description: "type de handicap",
      }),
    },
    [HOTEL_HANDICAP_VALUE_MOTEUR]: {
      label: defineMessage({
        defaultMessage: "moteur",
        description: "type de handicap",
      }),
    },
    [HOTEL_HANDICAP_VALUE_VISUEL]: {
      label: defineMessage({
        defaultMessage: "visuel",
        description: "type de handicap",
      }),
    },
    [HOTEL_HANDICAP_VALUE_AUDITIF]: {
      label: defineMessage({
        defaultMessage: "auditif",
        description: "type de handicap",
      }),
    },
  },
};

export const HOTEL_HANDICAP_LIST = Object.values(HOTEL_HANDICAPS);
